@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700;900&display=swap');

html {
	background: #1e1e1e;
	font-family: 'Comic Neue';
}
.App {
	text-align: center;
	color: white;
	min-height: 80vh;
}
* {
	margin-block-start: 0em;
	margin-block-end: 0em;
}
.messages {
	padding-top: 130px;
	padding-bottom: 50px;
	width: 700px;
	margin: 0 auto;
	max-width: 100%;
}
.header {
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.6),
		rgba(0, 0, 0, 0.2)
	);
	padding: 5px;
	backdrop-filter: blur(20px);

	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	flex-direction: column;
	width: 100%;
	height: 100px;
	z-index: 999;
}
.profile {
	width: 100px;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	margin-left: 10px;
	margin-right: 10px;
	padding: 10px;
}
.users {
	display: flex;
	align-items: center;
	justify-content: center;
}
.profilePic {
	width: 50px;
	height: 50px;
	border-radius: 990px;
	margin-left: 15px;
	margin-right: 15px;
}
.clownBG {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: url('https://d.newsweek.com/en/full/1600134/white-house.jpg');
	background-size: cover;
	opacity: 0.2;
	filter: blur(4px);
	z-index: -1;
}
.messageOuterLeft,
.messageOuterRight {
	display: block;
	margin-top: 10px;
	display: flex;
	align-items: flex-end;
	width: 700px;
	max-width: 100%;
}
.right {
	justify-content: flex-end;
}
.mediaElement {
	border-radius: 15px;
	overflow: hidden;
	max-width: 350px;
	text-align: left;
}
.message {
	min-height: 50px;
	display: block;
	max-width: 50%;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	text-align: left;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.message > p {
	display: block;
	margin: 10px;
	line-height: 20px;
	padding: 10px;
}
pre {
	display: none;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.messageOuterLeft,
.messageOuterRight {
	background-color: transparent;
	position: relative;
}

.messageOuterLeft:before,
.messageOuterRight:before {
	content: '';
	position: absolute;
	z-index: 0;
	bottom: 0;
	height: 10px;
	width: 20px;
	border-radius: 999px;
}
.messageOuterLeft:before {
	left: 80px;
	border-bottom-right-radius: 15px;
	background: #efefef;
}
.messageOuterRight:before {
	right: 80px;
	border-bottom-left-radius: 15px;
	background: #0f85ff;
}
